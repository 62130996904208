// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksIos-module--cardBg--90289";
export var cards = "TechStacksIos-module--cards--d822f";
export var cardsContent = "TechStacksIos-module--cardsContent--86a81";
export var description = "TechStacksIos-module--description--85cb2";
export var globalIndusIosTab = "TechStacksIos-module--globalIndusIosTab--08dab";
export var heading = "TechStacksIos-module--heading--05aee";
export var iconssCard = "TechStacksIos-module--iconssCard--5c749";
export var nav = "TechStacksIos-module--nav--ee6b9";
export var navItem = "TechStacksIos-module--nav-item--68af5";
export var navbarBlock = "TechStacksIos-module--navbarBlock--0f71a";
export var tabData = "TechStacksIos-module--tabData--b2ec2";
export var tech = "TechStacksIos-module--tech--0b1ca";
export var techIcon = "TechStacksIos-module--techIcon--188c4";
export var techImg = "TechStacksIos-module--techImg--25db1";
export var technologyIcon = "TechStacksIos-module--technologyIcon--8b8cb";