import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./TechMobile.scss"
import * as styles from "./TechStacksMobileIos.module.scss"

const TechStacksIosMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Tools",
      tech: [
        {
          name: "Sketch",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sketch_4fec875042.svg",
        },
        {
          name: "InVision Studio",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/invision_a22fd55549.svg",
        },
        {
          name: "Axure",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/axure_c54268f300.svg",
        },
        {
          name: "Craft",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/craft_90feaa9ecd.svg",
        },
        {
          name: "Proto.io",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/proto_io_04a19e1235.svg",
        },
        {
          name: "Adobe XD",
          icon: "https://invozone-backend.s3.amazonaws.com/C_programming_language_c5f5554b08.svg",
        },
        {
          name: "Marvel Studios",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Marvel_Studios_d6c4849523.svg",
        },
        {
          name: "Figma",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/figma_293cc3e92a.svg",
        },
        {
          name: "Adobe Photoshop",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_photoshop_f2e892ea1f.svg",
        },
        {
          name: "Adobe Illustrator",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_illustrator_10a0221425.svg",
        },
      ],
    },
    {
      title: "Technologies",
      tech: [
        {
          name: "HTML",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_861744c819.svg",
        },
        {
          name: "CSS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_a973e2f793.svg",
        },
        {
          name: "JavaScript",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_fc68280baa.svg",
        },
        {
          name: "SVG",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/svg_44dd84973b.svg",
        },
      ],
    },
    {
      title: "Typography",
      tech: [
        {
          name: "Google Fonts",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_fonts_58af18cebc.svg",
        },
        {
          name: "Adobe Fonts",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_fonts_4aad949110.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0)
  return (
    <div className="techIosMobile">
      <div className={styles.globalIndusIosTech}>
        <Container>
          <h2 className={styles.heading}>
            UI and UX Designing Tools and Technologies
          </h2>
          <div className={styles.description}>
            With our top technologies, your success is guaranteed.
          </div>
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`IosAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3) && e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2) && e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksIosMobile
