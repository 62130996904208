// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksMobileIos-module--cardBg--6447e";
export var cards = "TechStacksMobileIos-module--cards--a9013";
export var cardsContent = "TechStacksMobileIos-module--cardsContent--718a9";
export var description = "TechStacksMobileIos-module--description--b327d";
export var globalIndusIosTech = "TechStacksMobileIos-module--globalIndusIosTech--e1d35";
export var heading = "TechStacksMobileIos-module--heading--9939a";
export var iconssCard = "TechStacksMobileIos-module--iconssCard--0bb30";
export var nav = "TechStacksMobileIos-module--nav--416c7";
export var navItem = "TechStacksMobileIos-module--nav-item--a1ae0";
export var navbarBlock = "TechStacksMobileIos-module--navbarBlock--a2269";
export var tabData = "TechStacksMobileIos-module--tabData--a0d34";
export var tech = "TechStacksMobileIos-module--tech--f9b8a";
export var techIcon = "TechStacksMobileIos-module--techIcon--acf57";
export var techImg = "TechStacksMobileIos-module--techImg--10247";
export var technologyIcon = "TechStacksMobileIos-module--technologyIcon--d5014";