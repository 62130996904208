import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Banner from "../components/service-sections/Banner"
import Collaboration from "../components/service-sections/Collaboration"
import SubServices from "../components/service-sections/SubServices"
import Workflow from "../components/service-sections/Workflow"
import TechStacksIos from "../components/ui-ux-design/TechStacksIos"
import TechStacksIosMobile from "../components/ui-ux-design/TechStacksIosMobile"
import MainLayout from "../layouts/MainLayout"

const ServiceTemplate = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const sec_1 = data?.strapiPage?.sections[0]
  const sec_2 = data?.strapiPage?.sections[1]
  const sec_3 = data?.strapiPage?.sections[2]
  const sec_4 = data?.strapiPage?.sections[3]
  // const sec_5 = data?.strapiPage?.sections[4]
  const sec_6 = data?.strapiPage?.sections[5]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner {...sec_1} breadCrumb={data?.strapiPage?.buttons} />
      <SubServices {...sec_2} />
      <Collaboration {...sec_3} />
      <WorkPortfolio />
      <Workflow {...sec_4} />
      {!isMobile && !isTablet ? <TechStacksIos /> : <TechStacksIosMobile />}
      <Faqs strapiData={sec_6} />
      <ContactSales />
    </MainLayout>
  )
}

export const query = graphql`
  query getServicesData {
    strapiPage(slug: { eq: "ui-ux-design" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default ServiceTemplate

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  const sec_1 = data?.strapiPage?.sections[0]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image={sec_1?.secImages && sec_1?.secImages[0]?.url}
    />
  )
}
